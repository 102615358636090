export default {
    props: {
        idevento: String,
    },
    data() {
        return {
            dataApre: null,
            dataHorario: '',
            menu: false,
            horarios_: [],
            horarios: [],
            datas: [],
            datas_: [],
            pickerDate: '',
            idapresentacao: '',
            periodo: null,
            multiple: false,
            qtd: 0,
            disabledMinus: false,
            disabledAdd: false,
            carregando: false,
        }
    },
    computed: {
        dateFormated() {
            if(this.dataApre){
                if(typeof this.dataApre == 'object'){

                    let data = []

                    this.dataApre.forEach(item => {
                        data.push(this.$moment(item).format('DD/MM/YYYY'))
                    })

                    return data.join(' e ')

                }
      
                return this.$moment(this.dataApre).format('DD/MM/YYYY')
            }
            return null
        }
    },
    watch: {
        '$superingresso.apresentacoes'(e){
            e.dates.forEach(item => {
                this.datas.push(item.dia)
            })
            this.datas_   = this.datas
            this.dataApre = this.datas[0]
        },
        dataApre(e){
            this.dataHorario = ''
            this.periodo     = null
            this.horarios_   = []
            this.getApresentacoesHorarios(e)
        },
        async dataHorario(e){
            
            if(e){

                // this.$carrinho.AddPedido = 
                await this.getProdutos(e.idapresentacao)

                // RECARREGA OS ITENS PARA SER EXIBIDOS A QUANTIDADE RESERVADA
                
                if(this.$axios.getLocal('local_carrinho') && this.$axios.getLocal('local_carrinho').data.length > 0) {
                    let car  = this.$axios.getLocal('local_carrinho').data
                    let cc = this.$carrinho.AddPedido.tipo_produtos;

                    cc  = cc.find( item  => item.dsctipoproduto == this.periodo)
                    car = car.find( item => (item.dt_apre == this.dataApre || item.dt_apre == this.dataApre + ' ' + e.hora + ':00') && item.idapresentacao == e.idapresentacao)

                    
                    if(cc && car && car.produtos){
                        
                        cc.produtos.find( p => {
                            p.qtd = 0
                        })

                        car.produtos.forEach(item => {
                            cc.produtos.find( p => {
                                if(p.idproduto == item.idproduto){
                                    p.qtd = item.qtd
                                }
                            })
                        })
                    }
                    
                }

                if(this.$axios.getLocal('carrinho') && this.$axios.getLocal('carrinho').data.length > 0){
                    let car  = this.$axios.getLocal('carrinho').data;
                    let cc = this.$carrinho.AddPedido.tipo_produtos;

                    cc  = cc.find( item  => item.dsctipoproduto == this.periodo)
                    car = car.find( item => (item.dt_apre == this.dataApre || item.dt_apre == this.dataApre + ' ' + e.hora + ':00') && item.idapresentacao == e.idapresentacao)

                    
                    if(cc && car && car.produtos){

                        cc.produtos.find( p => {
                            p.qtd = 0
                        })

                        car.produtos.forEach(item => {
                            cc.produtos.find( p => {
                                if(p.idproduto == item.idproduto){
                                    p.qtd = item.qtd
                                }
                            })
                        })
                    }
                    
                }
                
            }
        }
    },
    methods: {
        clearHorario(){
            let autocompleteInput = this.$refs.dataHorario

            if(autocompleteInput && autocompleteInput.label != ''){
                autocompleteInput.blur();
            }
        },
        openClock(){
            this.$refs['dataHorario'].onClick()
        },
        disabledButton(item) {
            this.disabledMinus = true
            this.disabledAdd   = true
            const self = this
            setTimeout(() => {
                self.disabledMinus = false
                self.disabledAdd   = false
            }, 1500)
        },
        allowedDates(val){
            return this.datas.indexOf(val) !== -1
        },
        save(date) {
            this.$refs.menu.save(date)
        },
        getApresentacoesHorarios(dataApre) {
            this.$superingresso.getApresentacoesHorarios(this.idevento, dataApre)
            .then(response => {
                if(response){
                    response.apresentacoes = response.apresentacoes.filter(item => !item.esgotado)
                    this.getHorarios(response)
                }
            })
        },
        getHorarios(horarios){
            if(horarios){
                let apre = {
                    id: horarios.date.split(' ')[0],
                    itens: []
                }
                horarios.apresentacoes.forEach(item => {
                    apre.itens.push({
                        hora: this.$moment(item.dthr_apresentacao).format('H:mm'),
                        idapresentacao: item.idapresentacao
                    })
                })
                this.carregando = true
                this.horarios_  = apre
    
                if(this.horarios_.itens.length == 1){
                    this.dataHorario = this.horarios_.itens[0] 
                }
            }
        },
        async getProdutos(idApresentacao){
            this.$carrinho.AddPedido = []
            await this.$superingresso.getTipoProdutos(idApresentacao)
            .then((response) => {

                if(response && response.tipo_produtos){

                    let tipo_produtos = response
                    let periodo = []
                    tipo_produtos.tipo_produtos.forEach(item_temp => {
                        periodo.push({ dsctipoproduto: item_temp.dsctipoproduto, idtipoproduto: item_temp.idtipoproduto })
                        item_temp.produtos.forEach(item_temp2 => {
                            item_temp2.disabledAdd = false
                            item_temp2.disabledMinus = false
                            item_temp2.qtd = 0
                        })
                    })

                    // MERGE OBJETOS
                    if(this.$axios.getLocal('carrinho') && this.$axios.getLocal('carrinho').data) {
                        let carrinho = this.$axios.getLocal('carrinho').data;
                        carrinho.forEach(car => {
                            if(car.idapresentacao === idApresentacao){
                                car.produtos.forEach(prod => {
                                    tipo_produtos.tipo_produtos.forEach(item_temp => {
                                        item_temp.produtos.forEach(item_temp2 => {
                                            if(item_temp2.idproduto == prod.idproduto){
                                                item_temp2.qtd = prod.qtd
                                            }
                                        })
                                    })
                                })
                            }
                        })

                    }

                    periodo = periodo.filter( function(item, index, array) {
                        return !this[JSON.stringify(array[index].idtipoproduto)] && (this[JSON.stringify(item.idtipoproduto)] = true);    
                    }, Object.create(null))


                    tipo_produtos.periodo = periodo

                    tipo_produtos.idapresentacao = idApresentacao
                    this.$carrinho.AddPedido = tipo_produtos


                }
            })
        },

        
    },
    mounted() {
        const self = this
        let time = setInterval(() => {
            if(self.idevento){
                clearInterval(time)
                self.$superingresso.getApresentacoes(self.idevento);
            };
        }, 1000)

    }
}